import { tracking } from 'config/env.json'
window.GA_DIMENSION = {
  USER_TYPE: tracking.DIMENSION_USER_TYPE,
  TARGET_TYPE: tracking.DIMENSION_TARGET_TYPE,
}

window.GA_METRICS = {
  VIDEO_WATCH_DURATION: tracking.METRIC_VIDEO_WATCH_DURATION,
}

class Tracking {
  constructor(vm) {
    this.vm = vm
    this.timeout = null
    this.ga4Timeout = null
    this.userType = 'anonymous'
    this.ec = {
      addProductImpression: (products, list) => {
        return this.addProductImpression(products, list)
      },
      clickProduct: (product, list, position) => {
        return this.clickProduct(product, list, position)
      },
      addToCart: (product, productInstanceId, quantity) => {
        return this.addToCart(product, productInstanceId, quantity)
      },
      removeFromCart: (product, productInstance, quantity) => {
        return this.removeFromCart(product, productInstance, quantity)
      },
      checkout: (cart) => {
        const cartModel = _cloneDeep(cart)
        return this.checkout(cartModel)
      },
    }
  }


  addProductImpression(products, list = null) {
    if(!window.ga) return
    if(!Array.isArray(products)) return
    for(const index in products) {
      const product = products[index]
      let params = {
        id: product.id,
        name: product.name,
        category: !product.category ? undefined : product.category.name,
        brand: !product.brand ? undefined : product.brand.name,
        list: list || undefined,
        position: index,
        price: product.price,
      }
      ga('ec:addImpression', params)
    }
  }

  _addProductFieldObject(product, list = undefined, position = undefined) {
    let params = {
      id: product.id,
      name: product.name,
      category: !product.category ? undefined : product.category.name,
      brand: !product.brand ? undefined : product.brand.name,
      price: product.price,
    }
    if(position != undefined) params.position = position

    ga('ec:addProduct', {
      id: product.id,
      name: product.name,
      category: !product.category ? undefined : product.category.name,
      brand: !product.brand ? undefined : product.brand.name,
      list: list || undefined,
      position: position,
      price: product.price,
    })
  }

  clickProduct(product, list, position = undefined) {
    if(!window.ga) return
    this._addProductFieldObject(product, list, position)
    ga('ec:setAction', 'click', { list })
    ga('ec:setAction', 'detail')
  }

  addToCart(product, productInstanceId, quantity) {
    const instance = product.instances.find(instance => instance.id === productInstanceId)
    let params = {
      id: product.id,
      name: product.name,
      category: !product.category ? null : product.category.name,
      price: instance.price,
      quantity: quantity,
      brand: !product.brand ? null : product.brand.name,
      variant: !product.multi_models ? null : instance.name
    }

    ga('ec:addProduct', params)
    ga('ec:setAction', 'add')
  }

  removeFromCart(product, productInstance, quantity) {
    let params = {
      id: product.id,
      name: product.name,
      category: !product.category ? null : product.category.name,
      price: productInstance.price,
      quantity: quantity,
      brand: !product.brand ? null : product.brand.name,
      variant: !product.multi_models ? null : productInstance.name
    }

    ga('ec:addProduct', params)
    ga('ec:setAction', 'remove')
  }

  checkout(cart) {
    for(const cartItem of cart.items) {
      const product = cartItem.product
      const productInstance = cartItem.product_instance
      let params = {
        id: product.id,
        name: product.name,
        category: !product.category ? null : product.category.name,
        price: productInstance.price,
        quantity: cartItem.quantity,
        brand: !product.brand ? null : product.brand.name,
        variant: !product.multi_models ? null : productInstance.name
      }

      // ga('ec:addProduct', params)
      // ga('ec:setAction','checkout')
    }
  }

  delay(second) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve()
      }, second*1000)
    })
  }

  async pageView(title = null, meta = {}) {
    if(!title) return
    await this.delay(1)

    if(meta.userType != 'anonymous' && meta.userType != 'member') {
      meta.userType = 'anonymous'
    }

    if(window.ga) {
      ga('send', {
        hitType: 'pageview',
        title,
        page: window.location.pathname,
        [GA_DIMENSION.USER_TYPE]: this.userType,
      })
    }

    if(window.gtag) {
      if(this._isBackstage()) {
        console.warn('後台不發送GA4 pageView事件')
        return
      }
      const payload = {
        page_title: title,
        page_location: window.location.href,
        page_path: window.location.pathname,
      }

      gtag('event', 'page_view', payload)
    }
  }

  videoWatchDuration(title = null, meta = {}) {
    // if(!window.ga) return

    if(meta.userType != 'anonymous' && meta.userType != 'member') {
      meta.userType = 'anonymous'
    }

    let duration = parseInt(meta.duration)
    if(isNaN(duration)) return
    if(!duration) return
    if(!meta.targetId) return

    let fieldsObject = {
      [GA_DIMENSION.USER_TYPE]: meta.userType,
      [GA_DIMENSION.TARGET_TYPE]: meta.targetType,
      [GA_METRICS.VIDEO_WATCH_DURATION]: meta.duration,
    }

    // console.log(this._getEventAction(meta))
    // console.log(title)
    // console.log(fieldsObject)

    // if(window.ga) ga('send', 'event', 'videoWatchDuration', this._getEventAction(meta), `${title}`, fieldsObject)
    if(window.gtag) {
      const payload = {
        video_action: this._getEventAction(meta),
        event_label: `${title}`,
        targetType: meta.targetType,
        duration: meta.duration,
      }

      gtag('event', 'videoWatchDuration', payload);
    }

  }

  videoWatch(title = null, meta = {}) {
    if(meta.userType != 'anonymous' && meta.userType != 'member') {
      meta.userType = 'anonymous'
    }

    if(!meta.targetId) return

    let fieldsObject = {
      [GA_DIMENSION.TARGET_TYPE]: meta.targetType,
      [GA_DIMENSION.USER_TYPE]: meta.userType,
    }

    if(window.ga) {
      ga('send', 'event', 'videoWatch', this._getEventAction(meta), `${title}`, fieldsObject)
    }
    if(window.gtag) {
      const payload = {
        video_action: this._getEventAction(meta),
        event_label: `${title}`,
        targetType: meta.targetType,
      }

      gtag('event', 'videoWatch', payload);
    }
  }

  qualityChange(title = null, meta = {}) {
    if(meta.userType != 'anonymous' && meta.userType != 'member') {
      meta.userType = 'anonymous'
    }

    if(!meta.targetId) return
    if(window.gtag) {
      const payload = {
        video_action: this._getEventAction(meta),
        event_label: `${title}`,
        targetType: meta.targetType,
        quality: meta.quality,
      }

      gtag('event', 'qualityChange', payload);
    }
  }

  bufferingEnd(title = null, meta = {}) {
    if(meta.userType != 'anonymous' && meta.userType != 'member') {
      meta.userType = 'anonymous'
    }
    if(!meta.targetId) return

    if(window.gtag) {
      const payload = {
        video_action: this._getEventAction(meta),
        event_label: `${title}`,
        targetType: meta.targetType,
        bufferingTime: meta.bufferingTime,
      }
      
      gtag('event', 'bufferingTime', payload);
    }
  }

  videoWatchEnd(title = null, meta = {}) {
    if(meta.userType != 'anonymous' && meta.userType != 'member') {
      meta.userType = 'anonymous'
    }

    if(!meta.targetId) return

    let fieldsObject = {
      [GA_DIMENSION.TARGET_TYPE]: meta.targetType,
      [GA_DIMENSION.USER_TYPE]: meta.userType,
    }

    if(window.ga) {
      ga('send', 'event', 'videoWatchEnd', this._getEventAction(meta), `${title}`, fieldsObject)
    }
    if(window.gtag) {
      const payload = {
        video_action: this._getEventAction(meta),
        event_label: `${title}`,
        targetType: meta.targetType,
      }

      gtag('event', 'videoWatchEnd', payload);
    }
  }

  clickBanner(meta = {}) {

    if(window.gtag) {
      const payload = {
        event_label: `${meta.label}`,
        pageTitle: meta.pageTitle,
        bannerTitle: meta.bannerTitle,
        label: meta.label,
        path: meta.path,
      }
      
      gtag('event', 'clickBanner', payload);
    }
  }

  clickShowcase(meta = {}) {

    if(window.gtag) {
      const payload = {
        event_label: `${meta.label}`,
        pageTitle: meta.pageTitle,
        curationTitle: meta.curationTitle,
        itemTitle: meta.itemTitle,
        label: meta.label,
        path: meta.path,
      }
      
      gtag('event', 'clickShowcase', payload);
    }
  }

  changeUserType(isMember) {
    if(!window.ga) {
      window.clearTimeout(this.timeout)
      this.timeout = null
      this.timeout = setTimeout(() => {
        this.changeUserType(isMember)
      }, 1000)
      return
    }
    let userType = isMember === true ? 'member' : 'anonymous'
    this.userType = userType

    if(window.ga) ga('set', GA_DIMENSION.USER_TYPE, userType)
  }

  changeUserTypeForGa4(isMember) {
    if(!window.gtag) {
      window.clearTimeout(this.ga4Timeout)
      this.ga4Timeout = null
      this.ga4Timeout = setTimeout(() => {
        this.changeUserTypeForGa4(isMember)
      }, 1000)
      return
    }
    let userType = isMember === true ? 'member' : 'anonymous'
    this.userType = userType

    if(window.gtag) {
      gtag('set', 'user_properties', {
        userType
      })
    }
  }

  search(type, keywords) {
    if(window.ga) ga('send', 'event', 'search', type, keywords)
    if(window.gtag) {
      const payload = {
        search_type: type,
        event_label: `${keywords}`,
      }
      gtag('event', 'search', payload);
    }
  }

  _getEventAction(meta) {
    if(!meta) return null
    return `${meta.targetType}/${meta.targetId}`
  }

  _isBackstage() {
    const reg = /(unauthorized-admin|cms)/g
    return Array.isArray(this.vm.$route.path.match(reg)) && this.vm.$route.path.match(reg).length > 0
  }
}

export default (vm) => new Tracking(vm)