import moment from 'moment'
import html2plaintext from 'html2plaintext'
import 'moment/locale/zh-tw'
import momentDurationFormatSetup from 'moment-duration-format'

momentDurationFormatSetup(moment)
moment.locale('zh-tw')
import mustacheRender from 'libs/mustacheRender.js'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import _isEmpty from 'lodash/isEmpty'
import _isNil from 'lodash/isNil'
import _trim from 'lodash/trim'
import _findIndex from 'lodash/findIndex'
import _uniqBy from 'lodash/uniqBy'
import _sumBy from 'lodash/sumBy'
import crosstab from 'crosstab'
import qs from 'qs'
import clamp from 'clamp-js'
import numeral from 'numeral'
import Vue from 'vue'
import {youtubeApiKey} from 'config/env.json'
import axios from 'axios'

window._cloneDeep = _cloneDeep
window._isEqual = _isEqual
window._isEmpty = _isEmpty
window._isNil = _isNil
window._trim = _trim
window._findIndex = _findIndex
window._uniqBy = _uniqBy
window._sumBy = _sumBy
window.moment = moment
window.numeral = numeral
Vue.filter('timeDuration', (data) => {
    const number = parseInt(data)
    if (isNaN(number)) return '00:00:00'
    if (number <= 0) return '00:00:00'
    if (number < 10) return `00:00:0${number}`
    if (number < 60) return `00:00:${number}`
    let result = moment.duration(number, 'seconds').format("hh:mm:ss")
    return result
})

class helper {
    constructor() {
        this.moment = moment
        this.init()
    }

    async init() {
        const {default: faker} = await import(
            /* webpackChunkName: "faker" */
            /* webpackPrefetch: true */
            'faker/locale/zh_TW'
            )
        this.faker = faker
        this.crosstab = crosstab
    }

    crosstabBroadcast(key, data) {
        try {
            this.crosstab.broadcast(key, data, null)
        } catch (error) {
            console.log('device not support crosstab[crosstabBroadcast]')
        }
    }

    getRecursive(data, key, withoutWarning = false) {
        let result = _cloneDeep(data)
        try {
            let properties = key.split('.')
            if (properties.length == 1) return data[key]
            for (const property of properties) {
                if (result[property] === undefined || result[property] === null) return undefined
                result = result[property]
            }
        } catch (error) {
            if (process.env.NODE_ENV != 'testing') {
                if (!withoutWarning) console.warn(error)
            }
            return undefined
        }
        return result
    }

    setRecursive(data, key, value) {
        let target = data
        try {
            let properties = key.split('.')
            let last = properties.pop()
            for (const property of properties) {
                if (target[property] === undefined) {
                    target[property] = {}
                }
                target = target[property]
            }

            if (target === null || target === undefined) {
                target = {}
            }
            target[last] = value
        } catch (error) {
            console.warn(error)
            return data
        }
        return data
    }

    getCountryCodeFromLanguageCode(code, lowercase = false) {
        if (typeof code != 'string') return
        const partials = code.split('-')
        let result = partials.length == 1 ? partials[0] : partials[1]
        if (lowercase) return result.toLowerCase()
        return result
    }

    // logout confirm
    async logout(vm) {
        vm.$apopup.base({
            title: vm.i18n('action.logout'),
            content: vm.i18n('confirm.logout'),
            closeLabel: vm.i18n('action.cancel'),
            applyCallback: () => {
                vm.$api.member.public.logoutAllDevice()
                vm.$store.dispatch('token/clean')
                vm.$snotify.success(null, vm.i18n('logout.success'))
            },
        })
    }

    async getCustomVueComponent(component) {
        if (!component) return
        if (typeof component != 'function') return component
        const {default: result} = await component()
        return result
    }

    render(data, variable = {}) {
        return mustacheRender.render(data, variable)
    }

    getSuffixListByDevice() {
        const windowWidth = $(window).width()
        if (windowWidth < 660) return ['middle', 'small', 'tiny']
        if (windowWidth < 960) return ['middle', 'small', 'tiny']
        if (windowWidth < 1264) return ['large', 'middle', 'small', 'tiny']
        return ['xlarge', 'large', 'middle', 'small', 'tiny']
    }

    // 移除photo list的null項目
    getComputedPhotoList(photos) {
        if (!Array.isArray(photos)) return null
        const validPhotos = photos.filter(photo => !!photo)
        if (validPhotos.length == 0) return null
        return validPhotos
    }

    getPhotoListUrl(photos, suffixList = null) {
        if (!Array.isArray(photos)) return null
        photos = photos.filter(photo => !!photo)
        return this.getPhotoUrl(photos[0], suffixList)
    }

    getPhotoUrl(photo, suffixList = null) {
        if (!photo) return null
        if (photo.url) return photo.url
        if (!photo.size_list) return null
        if (!suffixList) suffixList = this.getSuffixListByDevice()
        const originUrl = photo.size_list.origin.url
        for (const suffix of suffixList) {
            if (photo.size_list[suffix] && photo.size_list[suffix].url) {
                return photo.size_list[suffix].url
            }
        }
        return originUrl
    }

    getPhotoUrlArray(photo) {
        if (!photo) return null
        if (photo.url) return [photo.url]
        if (!photo.size_list) return [photo.size_list]
        let result = []
        for (const suffix in photo.size_list) {
            const photoSizeObject = photo.size_list[suffix]
            if (!photoSizeObject.url) continue
            result.push(photoSizeObject.url)
        }
        return result
    }

    getSize(byte) {
        if (byte < 1024 * 1024) return `${(byte / 1024).toFixed(2)}KB`
        if (byte < 1024 * 1024 * 1024) return `${(byte / 1024 / 1024).toFixed(2)}MB`
        return `${(byte / 1000 / 1000 / 1024).toFixed(2)}GB`
    }

    isImage(file) {
        if (file instanceof File === false) return false
        if (new RegExp(/image\/png/).test(file.type)) return true
        if (new RegExp(/image\/jpg/).test(file.type)) return true
        if (new RegExp(/image\/jpeg/).test(file.type)) return true
        if (new RegExp(/image\/gif/).test(file.type)) return true
        return false
    }

    selectPhoto(vm, type, applyCallback, options = {}) {
        const single = options.single === true
        vm.$apopup.base({
            title: vm.i18n('photo.manager'),
            bodySlot: () => import('modules/photo/components/photoManager/container/popupContainer.vue'),
            width: vm.$isMobile ? '85vw' : '65vw',
            lockType: type,
            applyCallback,
            applyLabel: vm.i18n('photo.action.apply_photo'), ...options,
            disabledApply: (data) => {
                if (!Array.isArray(data)) return false
                if (single) return data.length != 1
                return data.length <= 0
            }
        })
    }

    testMailPrompt(vm, send, metaConfig = null) {
        vm.$apopup.prompt({
            width: '400px',
            title: vm.i18n('mail.action.test'),
            content: vm.i18n('mail.action.test.help'),
            disabledApply: (data) => {
                if (typeof data != 'string') return true
                for (const mail of data.split(',')) {
                    const isMail = vm.$validator.isEmail(mail)
                    if (!isMail) return true
                }
                return false
            },
            applyCallback: send, ...metaConfig,
        })
    }

    getTimeViaSecond(secs) {
        let format = 'HH:mm:ss'
        if (secs < 3600) format = 'mm:ss'
        return this.moment.utc(secs * 1000).format(format)
    }

    getSecond(time) {
        return this.moment(time, 'HH:mm:ss').diff(this.moment().startOf('day'), 'seconds')
    }

    getTimePartialViaSecond(secs) {
        let format = 'HH:mm:ss'
        const timeString = this.moment.utc(secs * 1000).format(format)
        return timeString.split(':')
    }

    getCountdown(time) {
        if (!time) return null
        const now = this.moment()
        const diffTime = this.moment(time).diff(now)
        const duration = this.moment.duration(diffTime, 'milliseconds')
        let hours = duration.hours() > 10 ? duration.hours() : `0${duration.hours()}`
        let minutes = duration.minutes() > 10 ? duration.minutes() : `0${duration.minutes()}`
        let seconds = duration.seconds() > 10 ? duration.seconds() : `0${duration.seconds()}`
        let result = seconds
        if (duration.minutes()) result = `${minutes}:${result}`
        if (duration.hours()) result = `${hours}:${result}`
        return result
    }

    afterLoginSuccess(vm, role) {
        this._redirectAfterLoginSuccess(vm)
        if (role == 'user') {
            this._emitLoginSuccessEventAfterLoginSuccess(vm)
        }
    }

    _redirectAfterLoginSuccess(vm) {
        const path = vm.$route.query.path
        if (!path) return
        vm.$router.replace({path})
    }

    async _emitLoginSuccessEventAfterLoginSuccess(vm) {
        try {
            await vm.$api.userAccount.admin.emitLoginSuccessEvent()
        } catch (error) {
            console.error(error)
        }
    }

    getBreakpointByWidth(width) {
        if (width < 600) return 'xs'
        if (width < 960) return 'sm'
        if (width < 1264) return 'md'
        if (width < 1904) return 'lg'
        return 'xl'
    }

    getDeviceByWidth(width) {
        const breakpoint = this.getBreakpointByWidth(width)
        if (breakpoint == 'xl' || breakpoint == 'lg') return 'desktop'
        if (breakpoint == 'md') return 'tablet'
        if (breakpoint == 'sm' || breakpoint == 'xs') return 'mobile'
        return null
    }

    selectIcon(vm, icon, applyCallback) {
        vm.$apopup.base({
            width: '600px',
            title: vm.i18n('page_category.data.icon'),
            bodySlot: () => import('components/iconSelector/iconSelector.vue'),
            defaultValue: icon,
            disabledApply: data => _isEmpty(_trim(data)),
            applyCallback,
        })
    }

    getSize(byte) {
        if (byte < 1024 * 1024) return `${(byte / 1024).toFixed(2)}KB`
        if (byte < 1024 * 1024 * 1024) return `${(byte / 1024 / 1024).toFixed(2)}MB`
        return `${(byte / 1000 / 1000 / 1024).toFixed(2)}GB`
    }

    verifyIdentity(vm, success = null, fail = null) {
        vm.$apopup.base({
            title: vm.i18n('user.verify.help.title'),
            bodySlot: () => import('components/adminVerify/adminVerify.vue'),
            disabledApply: (data) => _isEmpty(_trim(data)),
            applyCallback: async password => {
                try {
                    await vm.$api.userAccount.admin.verifyIdentity({password})
                    vm.$snotify.success(null, vm.i18n('user.verify-identity.success'))
                    if (typeof success === 'function') success()
                } catch (e) {
                    console.error(e)
                    vm.$snotify.error(null, vm.i18n('user.verify-identity.fail'))
                    if (typeof fail === 'function') fail()
                }
            },
        })
    }

    verifyMemberIdentity(vm, success = null, fail = null, cancelCallback = null) {
        vm.$apopup.base({
            title: vm.i18n('member.verify.help.title'),
            bodySlot: () => import('components/adminVerify/adminVerify.vue'),
            disabledApply: (data) => _isEmpty(_trim(data)),
            closeCallback: (isCancel) => {
                if (!isCancel) return
                if (typeof cancelCallback != 'function') return
                cancelCallback()
            },
            applyCallback: async password => {
                try {
                    await vm.$api.member.memberConsole.verifyIdentity({password})
                    vm.$snotify.success(null, vm.i18n('member.verify-identity.success'))
                    if (typeof success === 'function') success()
                } catch (e) {
                    console.error(e)
                    vm.$snotify.error(null, vm.i18n('member.verify-identity.fail'))
                    if (typeof fail === 'function') fail()
                }
            },
        })
    }

    formRules(vm) {
        return {
            textLengthRule: (value, length) => {
                const error = vm.i18n('base.validation.text_too_less', {min: length})
                if (typeof value != 'string') return error
                if (!vm.$validator.isLength(value, {min: length})) return error
                return true
            }, requiredRule: (value) => {
                return !_isEmpty(_trim(value)) || vm.i18n('base.validation.required')
            }, validEmail: value => {
                const error = i18n('base.validation.email.incorrect')
                if (this.validate().validEmail(vm, value) === false) return error
                return true
            }, hasLettersAndNumber: value => {
                const error = i18n('base.validation.should_contain_letters_and_numeric')
                if (this.validate().hasLettersAndNumber(value) === false) return error
                return true
            }, validIdNumber: value => {
                const error = i18n('base.validation.id_number.incorrect')
                if (this.validate().validIdNumber(value) === false) return error
                return true
            }, validPhone: (value, type = 0) => {
                if (this.validate().validPhone(value, type) === false) {
                    if (type == 0) return i18n('base.validation.phone.incorrect')
                    if (type == 1) return i18n('base.validation.phone.incorrect.1')
                    if (type == 2) return i18n('base.validation.phone.incorrect.2')
                    return ''
                }
                return true
            },
        }
    }

    validate() {
        return {
            validZip: (vm, value) => {
                if (typeof value != 'string') return false
                if (vm.$validator.isLength(value, {min: 3}) === false) return false
                if (vm.$validator.isNumeric(value) === false) return false
                return true
            }, validEmail: (vm, value) => {
                if (typeof value != 'string') return false
                if (vm.$validator.isEmail(value) === false) return false
                return true
            }, hasLettersAndNumber: value => {
                if (typeof value != 'string') return false
                const LETTERS_PATTEN = /[a-zA-Z]/
                const NUMERIC_PATTEN = /[0-9]/
                const hasLetters = new RegExp(LETTERS_PATTEN, 'g').test(value) == true
                const hasNumeric = new RegExp(NUMERIC_PATTEN, 'g').test(value) == true
                if (!hasLetters) return false
                if (!hasNumeric) return false
                return true
            }, minText(vm, value, length) {
                if (typeof value != 'string') return false
                return vm.$validator.isLength(value, {min: length})
            }, validIdNumber: value => {
                if (!value) return true
                if (typeof value != 'string') return false
                let id = value.trim();
                const verification = id.match("^[A-Z][12]\\d{8}$")
                if (!verification) {
                    return false
                }

                let conver = "ABCDEFGHJKLMNPQRSTUVXYWZIO"
                let weights = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1]

                id = String(conver.indexOf(id[0]) + 10) + id.slice(1);

                let checkSum = 0
                for (let i = 0; i < id.length; i++) {
                    let c = parseInt(id[i])
                    let w = weights[i]
                    checkSum += c * w
                }

                return checkSum % 10 == 0
            }, validPhone: (value, type = 0) => {
                if (!value) return true
                let phoneReg = ''
                if (type == 0) phoneReg = "^0[2,9][0-9]{8}$"
                if (type == 1) phoneReg = "^02[0-9]{8}$"
                if (type == 2) phoneReg = "^09[0-9]{8}$"
                const phone = value.trim();
                if (phone.length != 10 && phone.length > 0) return false
                const verification = phone.match(phoneReg)
                if (!verification) return false
                return verification
            },
        }
    }

    delay(second) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve()
            }, second * 1000)
        })
    }

    getAppCallback(methodName) {
        if (!window.HakkaTvJavascriptInterface) {
            console.warn('未偵測到HakkaTvJavascriptInterface')
            return () => {
            }
        }

        if (typeof window.HakkaTvJavascriptInterface[methodName] != 'function') {
            console.warn(`HakkaTvJavascriptInterface物件的屬性 ${methodName} 不為function無法使用`)
            return () => {
            }
        }

        return (...args) => {
            return window.HakkaTvJavascriptInterface[methodName](...args)
        }
    }

    getYoutubeUid(url) {
        if (typeof url != 'string') return null
        //短網址形式
        const short_pattern = /(https:\/\/youtu\.be\/)(.*)/
        if (url.match(short_pattern)) {
            let uid = url.replace(short_pattern, '$2')
            const hasQuery = new RegExp(/\?/).test(uid)
            if (hasQuery) {
                uid = uid.split('?')[0]
            }
            return uid
        }
        //正常形式
        const partial = url.split('v=')
        if (typeof partial[1] != 'string') return null
        const uid = partial[1].split('&')[0]
        return uid
    }

    getSeriesYearOptions() {
        const startYear = parseInt(this.moment().format('YYYY'))
        let result = []
        for (let year = startYear; year >= 2016; year--) {
            result.push(`${year}`)
        }
        result.push('2015以前')
        return result
    }

    getYearOptions(end = 2003) {
        const startYear = parseInt(this.moment().format('YYYY'))
        let result = []
        for (let year = startYear; year >= end; year--) {
            result.push(`${year}`)
        }
        return result
    }

    getMonthOptions() {
        let result = []
        for (let month = 1; month <= 12; month++) {
            let monthLabel = month < 10 ? `0${month}` : `${month}`
            result.push(monthLabel)
        }
        return result
    }

    parseSeriesCategory(nodes) {
        if (!Array.isArray(nodes)) return null
        let result = []
        let layer1Nodes = nodes.filter(node => node.depth == 1)
        let layer2Nodes = nodes.filter(node => node.depth == 2)
        for (const layer1Node of layer1Nodes) {
            layer1Node.id
            const subNodes = layer2Nodes
                .filter(layer2Node => {
                    return layer2Node.parent_id === layer1Node.id
                })
            result.push({
                layer1: layer1Node, layer2: subNodes,
            })
        }
        return result
    }

    // 是否含有兒少分類
    seriesCategoryHasChildrenType(nodes) {
        if (!Array.isArray(nodes)) return null
        return !!nodes.find(node => node.name == '兒少')
    }

    newsMediaType(news) {
        if (!news) return null
        if (!!news.video_id) return 'video'
        return 'photo'
    }

    getFileExtension(filename) {
        if (typeof filename != 'string') return null
        return filename.split('.').pop()
    }

    isVideo(fileType) {
        const whitelist = ['video/mp4', // mp4
            'video/x-matroska', // mov
            'video/x-quicktime', // mkv
            'video/quicktime', // mkv
        ]
        return whitelist.includes(fileType)
    }

    createSearchRoute(type, query) {
        let obj = {type}
        if (type === 'layer') {
            obj.q = qs.stringify({q: query}, {encode: false})
        } else {
            obj.q = query
        }
        return obj
    }

    isNumber(data) {
        const number = Number(data)
        if (isNaN(number)) return 0
        return number
    }

    async setupClamp(vm, selector, line = 1) {
        await this.delay(0.5)
        await vm.$nextTick()
        const $target = $(vm.$el).find(selector)[0]
        if (!$target) return
        clamp($target, {clamp: line})
    }

    getHtmlSummary(data, length = 30) {
        if (typeof data != 'string') return null
        const string = html2plaintext(data)
        if (typeof string != 'string') return null
        return string.slice(0, length)
    }

    validateVideoSeries(vm, video, showNotify = true) {
        if (!video) return null
        if (!Array.isArray(video.series)) return null
        const hasInvalidEp = video.series.some(series => {
            if (series.ep === undefined) return true
            if (series.ep === null) return true
            if (series.ep === '') return true
            return false
        })
        if (hasInvalidEp) {
            if (showNotify === true) {
                vm.$snotify.error('影集的集數為必填', vm.i18n('save.fail.invalid'),)
            }

            return {
                series: '集數為必填',
            }
        }
        return null
    }

    validateVideoChunk(vm, chunk) {
        let error = []
        if (!chunk.title) {
            error.push('影片標題為必填')
        }

        const seriesError = this.validateVideoSeries(vm, chunk, false)
        if (seriesError) {
            error.push(seriesError.series)
        }

        if (chunk.ranges.length == 0) {
            error.push('至少要設定一個剪輯片段')
        }

        if (error.length == 0) return null
        return error
    }

    async saveChunk(vm, chunk) {
        let createdVideo = await this._createVideo(vm, chunk)
        if (!createdVideo) return null
        const updatedVideo = await this._updateVideoData(vm, chunk, createdVideo)
        if (!updatedVideo) return null
        const clipResult = await this._createClips(vm, chunk, updatedVideo)
        if (!clipResult) return null
        return updatedVideo
    }

    async _createVideo(vm, chunk) {
        try {
            const video = await vm.$api.video.admin.create({
                title: chunk.title, type: 'stream',
            })
            return video
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async _updateVideoData(vm, chunk, createdVideo) {
        let params = {
            title: chunk.title,
            tag: chunk.tag,
            news: chunk.news.map(newsItem => newsItem.id),
            series: chunk.series.map(seriesItem => ({
                series_group_id: seriesItem.group_id, ep: seriesItem.ep,
            })),
        }
        try {
            const video = await vm.$api.video.admin.updateAll(createdVideo.id, params)
            return video
        } catch (error) {
            console.warn(error)
        }
    }

    async _createClips(vm, chunk, updatedVideo) {
        let params = {
            clips: this.getComputedChunkRange(chunk.ranges), source_video_id: chunk.sourceVideoId,
        }
        try {
            await vm.$api.video.admin.clips(updatedVideo.id, params)
            return true
        } catch (error) {
            console.warn(error)
            return false
        }
    }

    getComputedChunkRange(ranges) {
        return ranges.map(range => {
            const start = range[0]
            const end = range[1]
            return [start, end - start]
        })
    }

    getPercentageRatio(number, total) {
        if (!total) return 0
        return numeral((number / total)).format('0.00%')
    }

    getHourOfDate() {
        let result = []
        for (let hour = 0; hour < 24; hour++) {
            const hourLabel = hour < 10 ? `0${hour}` : `${hour}`
            result.push(hourLabel)
        }
        return result
    }

    frontDialogOptions() {
        return {
            titleClass: 'hakkaPopup',
            cardClass: 'hakkaPopupContent front-dialog',
            applyClass: 'ml-5 secondary-black-02',
            closeClass: 'secondary-black-02',
            contentClass: 'hakkaPopupText',
        }
    }

    adviceSize({width, height, ratio, width2, height2}) {
        return (width && height ? `尺寸建議：寬${width} X 高${height}` : '') +
            (width2 && height2 ? `、寬${width2} X 高${height2}` : '') +
            (ratio === '1:1' ?  `，圖片比例${ratio}（正方形）` : (ratio ? `，比例建議：寬高${ratio}` : ''))
    }

    getPhotoObjKernal({data}) {
        if (!data) return null
        const videoPhoto = this.handleVideoPhoto(data)
        if (videoPhoto) return videoPhoto
        if (data.photo) return data.photo
        return null
    }

    handleVideoPhoto(data) {
        if (data.video) {
            if (data.video.photo) {
                return data.video.photo
            }
            if (data.video.youtube_url) {
                const youtubeUid = this.getYoutubeUid(data.video.youtube_url)
                // 解析度較差
                return {url: `https://img.youtube.com/vi/${youtubeUid}/hqdefault.jpg`, isYT: true}

                // 解析度 1280×720 的版本（目前最大的尺寸）
                // return { url: `https://i.ytimg.com/vi_webp/${youtubeUid}/maxresdefault.webp` }
            }
        }
        return null
    }

    getPhotoObj({data, needPresetImg = false, presetImgRatio = "16:9"}) {
        const photo = this.getPhotoObjKernal({data})
        if (!photo) {
            if (!needPresetImg) return null
            if (presetImgRatio === "16:9") return {url: window.location.origin + require('@/assets/img/default/presetImg16-9.png')}
            if (presetImgRatio === "1:1") return {url: window.location.origin + require('@/assets/img/default/presetImg1-1.png')}
        }
        return photo
    }

    debounce(func, delay) {
        let timer = null
        return function () {
            let context = this;
            let args = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                func.apply(context, args)
            }, delay);
        }
    }

    changeText(text) {
        const table = {
            '製播準則': '法令規章', '新聞暨節目自律委員會': '節目暨新聞自律委員會',
        }
        const obj = _cloneDeep(table)
        for (let key in obj) {
            const value = obj[key]
            obj[value] = key
        }
        return !obj[text] ? text : obj[text]
    }

    change客臺(text) {
        if (typeof text !== 'string') return text
        const req = /客臺/gi
        return text.replace(req, '客台')
    }

    // 至少1個大寫英文字母、1個小寫英文字母、1個阿拉伯數字，10碼-20碼
    adminPasswordValidate(password) {
        const reg = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{10,20}$/
        return reg.test(password)
    }

    async getYoutubeData(youtubeUid) {
        try {
            const response = await axios({
                method: 'GET', url: `https://www.googleapis.com/youtube/v3/videos`, params: {
                    part: 'snippet', id: youtubeUid, key: youtubeApiKey,
                },
            })
            const data = response.data.items[0].snippet
            return {
                title: data.title,
                description: data.description,
                photo: `https://img.youtube.com/vi/${youtubeUid}/hqdefault.jpg`
            }
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    isFeature(time = null) {
        if (!time) return false
        const now = moment()
        return moment(time).isAfter(now)
    }
}

export default new helper()
